import styles from "./PortfolioScreen.module.scss";
import { HoverCards } from "../../components/hovercards";
import { ArrowButton } from "../../components/arrowbutton";
import { useState } from "react";

const PortfolioScreen = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    return (
        <div className={`container ${styles.container}`}>
            <div className={styles['container-item']}>
                <div className={styles['left-arrow']} onClick={() => {setActiveIndex(activeIndex > 0 ? activeIndex-1 : CardItems.length-1)}}>
                    <ArrowButton />
                </div>
                <HoverCards cards={CardItems} activeIndex={activeIndex} />
                <div className={styles['right-arrow']} onClick={() => {setActiveIndex(activeIndex < CardItems.length-1 ? activeIndex+1 : 0)}}>
                    <ArrowButton />
                </div>
            </div>
        </div>
    );
}
const CardItems = [
    {title: "Brocolli", description: "A fitness splash screen project utilizing the 3D Javascript library, three.js", url: "https://brocolli.herokuapp.com/", img: "/images/Broccoli.png"},
    {title: "Ping", description: "Geolocation-based app that won best design at the 24-hour RPI hackation", url: "#", img: "/images/Ping.png"},
    {title: "Greenhouse", description: "An audio visualization project featuring music from a very talented musician friend", url: "https://greenhouse-effect.herokuapp.com/", img: "/images/Greenhouse.png"},
    {title: "Lightbulbs", description: "A project tackling the lightbulb and switch problem", url: "https://light-bulbs.herokuapp.com/", img: "/images/Lightbulbs.png"}

];


export default PortfolioScreen;