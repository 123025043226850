import styles from "./AboutScreen.module.scss";
import { useEffect } from "react";
import cowSilhouette1 from "../../assets/images/cow_silhouette1.svg";

const AboutScreen = () => {
    useEffect(() => {
       // if (window.confirm("Redirecting to About.Me page, confirm?"))
       //     window.location.href = "https://about.me/wcgpr0";
    }, []);

    return (
        <>
        <div className={`container ${styles.container}`}>
            <div className={styles["container-item"]}>
                <div className={styles["circular-sb"]}>
                    <a href="https://about.me/wcgpr0">About.Me</a>
                    <div className={styles.circle1}></div>
                    <div className={styles.circle2}></div>
                </div>
            </div>
            <div className={styles["container-item"]}>
                <h2 className={styles.strikethrough}>Under construction. Well, this is embarassing.</h2>
            </div>
            <div className={styles["container-item"]}>
                <div className={styles.frame}>
                    <img src={"/images/profile_pic.jpg"} />
                </div>
            </div>
            <div className={styles["container-item"]}>
                <img className={styles.landscape} />
        </div>     
        </div>
        </>
    );
}

export default AboutScreen;