import styles from "./hovercards.module.scss";

const HoversCards = ({cards, activeIndex}) => {
    return (
        <div className={styles['cards-group']}>
            {cards.map((card, i) => 
                <div className={`${styles.card} ${i === activeIndex ? styles.active : ""}`} key={i+activeIndex}>
                    <div className={styles.blur} style={{backgroundImage: `url(${card.img})` }} />
                    <div className={styles.bg} style={{backgroundImage: `url(${card.img})`}} />
                    <div className={styles.block} style={{backgroundImage: `url(${card.img})` }} onClick={() => window.location.href = card.url}>
                        <h2>{card.title}</h2>
                        <p>{card.description}</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default HoversCards;