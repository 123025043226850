import styles from "./ServiceScreen.module.scss";

const ServiceScreen = () => {
    return (
        <div className={`container ${styles.container}`}>
            <div className={styles["container-item"]}>
                <h4>what I do</h4>
            </div>
            <div className={styles["container-item"]}>
                <ul className={styles["card-core"]}>
                    <li className={styles.card}>
                        <h3>UI/UX Design</h3>
                        <p>Designing striking wireframes leaving you with the best experience.</p>
                    </li>
                    <li className={styles.card}>
                        <h3>Web Design</h3>
                        <p>Developing fully-responsive and interactive websites on the net.</p>
                    </li>
                    <li className={styles.card}>
                        <h3>Mobile Apps</h3>
                        <p>Utilizing the best technology for native apps ready to be deployed.</p>
                    </li>
                    <li className={styles.card}>
                        <h3>Graphics</h3>
                        <p>Creating the best kinds of imagery and graphics.</p>
                    </li>
                    <li className={styles.card}>
                        <h3>Marketing</h3>
                        <p>Providing extensive care and support for branding.</p>
                    </li>
                    <li className={styles.card}>
                        <h3>Animation</h3>
                        <p>Coming Soon...</p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ServiceScreen;