import styles from "./HomeScreen.module.scss";

const HomeScreen = () => {

    return(
        <>
        <div className={`container ${styles.container}`}>
            <div className={styles["container-item"]}>
                <div>
                    <h2 style={{ lineHeight: "90px" }}>
                        <span style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)", textTransform: "uppercase" }}> With a passion for making <font style={{ color: "#15e1ff" }}>design</font> and <font style={{ color: "#15e1ff" }}>development</font> fun,</span>
                        <span style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)", textTransform: "uppercase" }}>  I am a Full Stack Developer and art enthusiast.</span>
                    </h2>
                </div>
                <div className={styles.logo_pic}>
                    <img className={styles.logo_pic_image_} src="/images/logo_.jpg" />
                    <img className={styles.logo_pic_image} src="/images/logo.jpg" />
                </div>
            </div>
        </div>
        </>
    );
};

export default HomeScreen;